import { array } from "valibot"

import { PaginationParams } from "@finq/app-base/lib/types/common"

import {
  PortfolioType,
  StockComparison,
  stocksPortfolioTypesSchema,
} from "@finq/stocks/types/stocks-portfolios"

import { BaseApiService, KyOptions } from "../../../../apps/base/src/composables/services/api.service"
import {
  StockData,
  StocksAllocationParams,
  stockArticleSchema,
  stockByIdSchema,
  stockComparisonChartSchema,
  stockDataSchema,
  stockRankingSchema,
  stockSectorSchema,
  stockYieldSchema,
  stocksAllocationSchema,
  stocksTransactionsSchema,
} from "../../types"

export const StocksSortBy = {
  description: "description",
  symbol: "symbol",
  price: "price",
  priceChange: "priceChange",
  rankOrder: "rankOrder",
  rating: "rating",
} as const

export interface GetStocksParams extends PaginationParams {
  sortBy?: (typeof StocksSortBy)[keyof typeof StocksSortBy]
  order?: "ASC" | "DESC"
  search?: string
  sectors?: string
  momentum?: "positive" | "negative"
  withGraph?: boolean
}

export type StocksPortfolioPeriod = "MAX" | "1Y" | "YTD" | "1M" | "5D"

export interface GetStocksPortfoliosParams {
  period?: StocksPortfolioPeriod
  interval?: "daily" | "weekly" | "monthly"
  portfolioType?: PortfolioType
}

class StocksServiceRaw extends BaseApiService {
  getStocks = async (config: KyOptions<GetStocksParams> = {}) => {
    return this.instance
      .get("/stocks", config)
      .then((res) => this.parsePaginated(res.data, array(stockDataSchema)))
  }

  getStockById = async (id: StockData["id"], config: KyOptions = {}) => {
    return this.instance
      .get(`/stocks/${id}`, config)
      .then(this.getData)
      .then((data) => this.parse(data, stockByIdSchema))
  }

  getStockArticles = async (id: StockData["id"], config: KyOptions = {}) => {
    return this.instance
      .get(`/stocks/${id}/articles`, config)
      .then(this.getData)
      .then((data) => this.parse(data, array(stockArticleSchema)))
  }

  getStockYields = async (id: StockData["id"], config: KyOptions = {}) => {
    return this.instance
      .get(`/stocks/${id}/yields`, config)
      .then(this.getData)
      .then((data) => this.parse(data, array(stockYieldSchema)))
  }

  getStockRanking = async (id: StockData["id"], config: KyOptions = {}) => {
    return this.instance
      .get(`/stocks/${id}/ranking`, config)
      .then(this.getData)
      .then((data) => this.parse(data, array(stockRankingSchema)))
  }

  search = async (search: string, config: KyOptions = {}) => {
    return this.getStocks({ params: { search }, ...config })
  }

  getSectors = async (config: KyOptions = {}) => {
    return this.instance
      .get("/stocks/sectors", config)
      .then((res) => res.data)
      .then((data) => this.parse(data, array(stockSectorSchema)))
  }

  getMomentum = async (config: KyOptions = {}) => {
    return this.instance
      .get("/stocks/momentum", config)
      .then((res) => this.parsePaginated(res.data, array(stockDataSchema)))
  }

  getComparison = async (config: KyOptions = {}) => {
    return this.instance
      .get("/stocks/comparisonyields", config)
      .then(this.getData)
      .then((data) => this.parse(data, stockComparisonChartSchema))
  }

  /** Stocks Portfolios API's */

  getStocksPortfoliosTypes = async (config: KyOptions = {}) => {
    return this.instance
      .get("/stocks/portfolios/types", config)
      .then(this.getData)
      .then((data) => this.parse(data, array(stocksPortfolioTypesSchema)))
  }

  getStocksPortfoliosYields = async (config: KyOptions<GetStocksPortfoliosParams> | undefined = {}) => {
    const { portfolioType } = config.params || {}

    if (!portfolioType) {
      throw new Error("portfolioType is required")
    }

    return this.instance.get("/stocks/portfolios/compareyields", config).then(this.getData)
  }

  getPortfoliosAllocation = async (config: KyOptions<StocksAllocationParams> = {}) => {
    return this.instance
      .get("/stocks/portfolios/allocations", config)
      .then((res) => this.parsePaginated(res.data, array(stocksAllocationSchema)))
  }

  getPortfoliosTransactions = async (config: KyOptions<StocksAllocationParams> = {}) => {
    return this.instance
      .get("/stocks/portfolios/transactions", config)
      .then((res) => this.parsePaginated(res.data, array(stocksTransactionsSchema)))
  }
}

/** Exporting a singleton object to entire app */
export const StocksService = new StocksServiceRaw()
